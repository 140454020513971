import { getStripeConnectSessionForUser } from '@api/user';
import { loadConnectAndInitialize } from '@stripe/connect-js';
import { ConnectAccountOnboarding, ConnectComponentsProvider } from '@stripe/react-connect-js';
import React, { useState } from 'react';

const AccountOnboardingUI = ({ onExit }) => {
  const [stripeConnectInstance] = useState(() => {
    const fetchClientSecret = async () => {
      // Fetch the AccountSession client secret
      const response = await getStripeConnectSessionForUser();
      if (!response.status == 200) {
        // Handle errors on the client side here
        const { error } = await response.data;
        console.error('An error occurred: ', error);
        //document.querySelector('#error').removeAttribute('hidden');
        return undefined;
      } else {
        const { client_secret: clientSecret } = await response.data;
        //document.querySelector('#error').setAttribute('hidden', '');
        return clientSecret;
      }
    };

    return loadConnectAndInitialize({
      // This is your test publishable API key.
      publishableKey:
        'pk_test_51Gr4YqAt5pYWicpGrVErNE41qPrmHkmcJhveIXIa8cYsg0etCR9pw1uToKovZdvHkIcaVA3Ejgqsckqji7QobyHT00VHlvLHvZ',
      fetchClientSecret: fetchClientSecret,
      appearance: {
        variables: {
          colorPrimary: '#6997FF',
          buttonPrimaryColorBackground: '#6997FF',
          buttonPrimaryColorBorder: '#6997FF',
          buttonPrimaryColorText: '#FFFFFF',
          actionPrimaryColorText: '#6997FF',
          actionPrimaryTextDecorationColor: '#6997FF',
          formHighlightColorBorder: '#6997FF',
          formAccentColor: '#6997FF',
          colorDanger: '#E926BF',
          badgeSuccessColorText: '#3CE5F4',
          badgeWarningColorText: '#D73EC6',
          badgeDangerColorText: '#D73EC6',
          spacingUnit: '12px',
          fontFamily: "'Montserrat',Helvetica,Arial,sans-serif"
        }
      }
    });
  });

  return (
    <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
      <ConnectAccountOnboarding
        onExit={() => {
          console.log('The account has exited onboarding');
          onExit();
        }}
        collectionOptions={{
          fields: 'eventually_due',
          futureRequirements: 'include'
        }}
      />
    </ConnectComponentsProvider>
  );
};

function StripeOnboarding({ onExit }) {
  return <AccountOnboardingUI onExit={onExit} />;
}

export default StripeOnboarding;
